import React, { useEffect } from "react"
import styled from "styled-components"
import Trash from "../../../icons/tools/trash.svg"

const PdfdisplayStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 283px;
  min-width: max-content;
  width: 100%;
  max-width: max-content;
  /* transition: order 1s; */
  /*test*/
  order: 10;
  ${props => `order: ${props.order};`}

  user-select: none;
  touch-action: none;
  z-index: 1;

  img {
    max-width: max-content;
    margin: 0;
    border: 1px solid rgba(0, 0, 0, 0);
    transition: border 0.5s, outline 0.5s;
    outline: rgba(216, 219, 253, 0) solid 10px;
    box-shadow: 0px 13px 27px -5px rgba(50, 50, 93, 0.25),
      0px 8px 16px -8px rgba(0, 0, 0, 0.3);

    /* user-select: none;
    pointer-events: none; */
    touch-action: none;

    :hover {
      outline: rgba(216, 219, 253, 0.5) solid 10px;
      border: 1px solid #425466;
    }
  }

  .above {
    img {
      outline: rgba(216, 219, 253, 0.5) solid 10px;
      border: 1px solid #425466;
    }
  }
`
const Name = styled.div`
  font-size: 15px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #0b263f;
  margin-top: 16px;
  max-width: 200px;
  min-height: 48px;
  max-height: 48px;
  display: block;
  white-space: normal;

  height: 48px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

const Pages = styled.div`
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  color: #425466;
  margin-top: 4px;
  width: 100%;
  text-align: center;
`

const ImageHolder = styled.div`
  position: relative;
  cursor: move;

  svg {
    transition: opacity 0.2s;
    opacity: 0;
    position: absolute;
    right: 0;
    bottom: 0;
    cursor: pointer;
  }

  :hover {
    svg {
      opacity: 1;
    }
  }
`
const TestBlock = styled.div`
  width: 120px;
  height: 120px;
  background-color: red;
`

function PdfdisplayRearrange({ element, name, pages, file, order }) {
  return (
    <PdfdisplayStyle
      order={order}
      className={`pdfdisplay`}
      id={`pdfdisplay` + order}
      draggable
      style={{ order: order }}
      data-img={`${element.toString()}`}
    >
      <ImageHolder>
        {/* <TestBlock /> */}
        <img src={element} alt="Pdf display" draggable="false" />
        {/* <canvas /> */}
        {/* <Trash className="delete" id={`delete` + order} file={file} /> */}
      </ImageHolder>
      <Pages>{pages} page</Pages>
    </PdfdisplayStyle>
  )
}

export default PdfdisplayRearrange
