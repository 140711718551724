import React from "react"
import {
  Container,
  Title,
  Description,
  DescriptionBox,
  ImageContainter,
  SvgHolder,
} from "../../../ToolsStyles/ShortDescriptionStyled"
import PDFframe from "../../../merge/MergeAssets/MergePDF.jpg"

import Desc1 from "../../../../../icons/tools/lp/merge/DescIcon1.svg"
import Desc2 from "../../../../../icons/tools/lp/merge/DescIcon2.svg"
import Desc3 from "../../../../../icons/tools/lp/merge/DescIcon3.svg"
import Desc4 from "../../../../../icons/tools/lp/merge/DescIcon4.svg"
import Desc5 from "../../../../../icons/tools/lp/merge/DescIcon5.svg"
import Desc6 from "../../../../../icons/tools/lp/merge/DescIcon6.svg"

const ShortDescriptions = () => {
  return (
    <Container>
      <DescriptionBox>
        <SvgHolder>{/* <Desc1 /> */}</SvgHolder>
        <Title>No Installation or Sign-up required</Title>
        <Description>
          No downloading software, no signing up necessary. Just visit this
          webpage from your browser, and get started!
        </Description>
      </DescriptionBox>

      <DescriptionBox>
        <SvgHolder>{/* <Desc2 /> */}</SvgHolder>
        <Title>Safe & Secure — Your Privacy Matters to Us</Title>
        <Description>Uploaded files are deleted after 24 hours.</Description>
      </DescriptionBox>

      <DescriptionBox>
        <SvgHolder>{/* <Desc3 /> */}</SvgHolder>
        <Title>Why Rearrange PDF Pages?</Title>
        <Description>
          Rearrange PDF pages to sort and organize your documents. Reorder the
          pages to your liking.{" "}
        </Description>
      </DescriptionBox>

      <DescriptionBox>
        <SvgHolder>{/* <Desc4 /> */}</SvgHolder>
        <Title>100% Free to Use</Title>
        <Description>
          Our Rearrange PDF Pages tool completley free! There are no limits on
          the number of PDFs you can rearrange, or the number of times you can
          use this tool.
        </Description>
      </DescriptionBox>

      <DescriptionBox>
        <SvgHolder> {/* <Desc5 /> */}</SvgHolder>
        <Title>Easy to Use</Title>
        <Description>
          Rearrange PDF Pages, instantly. Simply upload your PDF, sort the
          pages, and download!
        </Description>
      </DescriptionBox>

      <DescriptionBox>
        <SvgHolder> {/* <Desc6 /> */}</SvgHolder>
        <Title>Works on Any Device</Title>
        <Description>
          Instantly rearrange PDF pages right in your browser, on any device!
          Windows, Mac, Chrome, Linux — you name it!
        </Description>
      </DescriptionBox>
    </Container>
  )
}

export default ShortDescriptions
