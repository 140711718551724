import React from "react"
import ReactDOMServer from "react-dom/server"

import PdfdisplayRearrange from "../rearrange/PdfdisplayRearrange"

export default function displayPdfSyncRearrange(
  pdfData,
  fileData,
  appendTo,
  order,
  numPages,
  setFileRendered,
  removePassProtected,
  setErrorFiles,
  repoLength
) {
  return new Promise(async (resolve, reject) => {
    let protectedPass = false
    const pdf = await window.pdfjsLib
      .getDocument({
        data: pdfData,
      })
      .promise.catch(() => {
        console.log("Password protected: ", fileData.name)
        removePassProtected(fileData.name)
        setErrorFiles(erF => [...erF, fileData.name])

        protectedPass = true
      })

    if (protectedPass) {
      return resolve(false)
    }

    const pages = pdf.numPages

    for (let i = 0; i < pages; i++) {
      const page = await pdf.getPage(i + 1)
      const viewport = page.getViewport({ scale: 0.337 })

      const canvas = document.createElement("canvas")
      const div = document.createElement("div")

      const context = canvas.getContext("2d")
      canvas.height = viewport.height
      canvas.width = viewport.width
      canvas.style.marginDown = "64px"

      const renderContext = {
        canvasContext: context,
        viewport: viewport,
      }

      await page.render(renderContext).promise

      var dataURL = canvas.toDataURL()

      let str = JSON.stringify({ name: fileData.name, size: fileData.size })
      setFileRendered((i + 1) / pages)

      document.getElementById(
        appendTo
      ).innerHTML += ReactDOMServer?.renderToString(
        <PdfdisplayRearrange
          element={dataURL}
          name={fileData.name}
          pages={i + 1}
          file={str}
          order={i + 1}
        >
          {" "}
          test{" "}
        </PdfdisplayRearrange>
      )
    }
    resolve(true)
  })
}
