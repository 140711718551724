import React from "react"
import {
  ChildContainer,
  Description,
  ParentContainer,
  Title,
  Image,
  TitleSub,
  SubText,
  Ul,
  LearnMore,
  SvgHolder,
  TitleHolder,
} from "../../../ToolsStyles/UsefulToolsStyled"
import Delete from "../icons/delete.svg"
import Extract from "../icons/extract.svg"

const UsefulTools = () => {
  return (
    <ParentContainer>
      <TitleHolder>
        <Title>Continue to organize this PDF</Title>
        <Description>
          Here are a couple other tools that you may want to use on your current
          PDF document.
        </Description>
        <LearnMore to="/pdf-online-tools/">
          View all Online tools &rarr;
        </LearnMore>
      </TitleHolder>

      <ChildContainer>
        <SvgHolder>
          <Delete />
        </SvgHolder>
        <TitleSub>Delete PDF Pages</TitleSub>
        <SubText>Delete pages from a PDF.</SubText>
        <LearnMore to="/delete-pdf-pages/">Continue &rarr;</LearnMore>
      </ChildContainer>

      <ChildContainer>
        <SvgHolder>
          <Extract />
        </SvgHolder>
        <TitleSub>Extract PDF Pages</TitleSub>
        <SubText>Extract the exact page you need from a PDF document.</SubText>
        <LearnMore to="/extract-pdf-pages/">Continue &rarr;</LearnMore>
      </ChildContainer>
    </ParentContainer>
  )
}

export default UsefulTools
