import React, { useState, useEffect, useRef, useContext } from "react"
import { GlobalAuthContext } from "../../../Contexts/GlobalAuthContext"
import styled from "styled-components"

import displayPdfSyncRearrange from "../helpers/displayPdf-Rearrange"
import { FileUploader } from "react-drag-drop-files"
import TryPdf from "../../shared/TryPdf"
import Add from "../../../icons/tools/Add.svg"
import ArrowRight from "../../../icons/tools/arrow-right.svg"
import RearrangeIcon from "../../../icons/tools/rearrange/rearrange.svg"
import MergedIcon from "../../../icons/tools/mergedIcon.svg"
import ErrorMessage from "../ErrorMessage"
import UploadIcon from "../../../icons/tools/upload.svg"
import AddSimple from "../../../icons/tools/addSimple.svg"
import ArrowTry from "../../../icons/tools/tryPdfArrow.svg"
import PrivacyQuestion from "../../../icons/tools/PrivacyQuestion.svg"
import GDPRwarning from "../GlobalToolComponents/GDPRwarning"
import Title from "../GlobalToolComponents/ToolH2tag"
import Warning from "../Warning"
import RestartIcon from "../../../icons/tools/Restart.svg"
import UsefulTools from "./Landing/UsefulTools/UsefulTools"
import { throttle } from "lodash"

import {
  DownloadScreen,
  CanvasStyle,
  LoadingScreen,
  Pdfpreview,
} from "../Styles"
import Disclaimer from "../GlobalToolComponents/Disclaimer"
import { RearrangeFiles } from "./Actions"

const LoadingCircle = styled.div`
  border: 3px solid #f3f3f3; /* Light grey */
  border-top: 3px solid rgba(0, 0, 0, 0);
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin-right: 12px;
  animation: spin 1s linear infinite;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`
const FileUploaderStyle = styled.div`
  margin: auto;
  max-width: 532px;
  width: 100%;
  margin-top: 60px;

  .drop_zone {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed red;
    width: 100%;
    min-height: 374px;
    background: #f5f6fc;
    border: 1px dashed #6361ff;
    border-radius: 2px;
    margin: 8px 0;
  }

  :hover {
    max-width: 548px;
    .drop_zone {
      min-height: 390px;
      background: #ebeeff;
      margin: 0;
    }
  }

  .column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .title {
      font-size: 18px;
      line-height: 28px;
      color: #425466;
      text-align: center;
      margin-top: 25px;
      margin-bottom: 8px;
      :after {
        content: "Drag and drop your PDF file here";
      }
      @media (max-width: 744px) {
        :after {
          content: "Tap to upload PDF file here";
        }
      }
    }
    .info {
      font-size: 13px;
      line-height: 22px;
      text-align: center;
      color: #6361ff;
      text-transform: capitalize;

      :after {
        content: "PDF files smaller than 20MB";
      }
      @media (max-width: 744px) {
        :after {
          content: "PDF files smaller than 20MB";
        }
      }
    }

    button {
      all: unset;
      margin-top: 40px;
      font-size: 17px;
      line-height: 20px;
      color: #ffffff;
      padding: 12px 24px;
      gap: 12px;
      background: #6361ff;
      border-radius: 8px;
      cursor: pointer;
      @media (max-width: 744px) {
        display: none;
      }
    }
  }
`
const Offline = styled.div`
  background: #e3e8ee;
  border-radius: 2px;
  width: 73px;
  height: 26px;
  font-size: 13px;
  line-height: 22px;
  text-transform: uppercase;
  color: #6a7485;
  margin-right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`
const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;

  :hover {
    .warning {
      opacity: 1;
    }
  }
`

const ToPdf = styled.div`
  font-size: 14px;
  line-height: 24px;
  color: #333333;
  a {
    color: #6361ff;
    text-decoration: none;
  }

  .desktop {
    display: inline-block;
    @media (max-width: 565px) {
      display: none;
    }
  }
`
const CtaPopup = styled.div`
  color: #6361ff;
  text-decoration: none;
  display: inline;
  cursor: pointer;
  white-space: nowrap;
`
const SingleFileUploadStyle = styled.div`
  min-width: 205px;
  min-height: 283px;
  width: max-content;
  margin: 0;
  margin-bottom: 90px;
  display: inline-block;

  .drop_zone {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 283px;
    background: #f5f6fc;
    border: 1px dashed #6361ff;
    border-radius: 2px;
    cursor: pointer;

    :hover {
      background: #ebeeff;
    }
  }
  .column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    .title {
      font-size: 15px;
      line-height: 18px;
      text-align: center;
      color: #6361ff;
      font-weight: 800;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .info {
      max-width: 97px;
      font-size: 13px;
      line-height: 15px;
      text-align: center;
      color: #6361ff;
      white-space: pre-wrap;
    }
  }
`

const Pdfholder = styled.div`
  min-height: 283px;
  display: flex;
  align-items: flex-end;
  gap: 48px;
  padding-top: 32px;
  padding-bottom: 32px;
`

const MergeButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  gap: 12px;
  width: 170px;
  white-space: nowrap;
  transition: background-color 1s;
  height: 48px;
  background: #6361ff;
  border-radius: 8px;

  font-size: 17px;
  line-height: 20px;
  color: #ffffff;
  cursor: pointer;

  svg {
    min-width: 24px;
    min-width: 24px;
  }
  ${props => props.disabled && "background: #CDCDCD;"}

  @media (max-width: 440px) {
    width: 90%;
  }
`

const AddFile = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  gap: 12px;
  width: 147px;
  height: 48px;
  background: #f5f9fc;
  border: 2px solid #6361ff;
  border-radius: 8px;
  font-size: 17px;
  color: #6361ff;
  white-space: nowrap;
  cursor: pointer;
  svg {
    min-width: 24px;
    min-width: 24px;
  }
  @media (max-width: 440px) {
    width: 90vw;
  }
`

const SecondScreen = styled.div`
  /* ${props => props.hide && "display: none;"} */
  /* transform: translateY(124px); */
`

const ButtonHolder = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 40px;

  @media (max-width: 440px) {
    margin-top: 96px;

    flex-direction: column;
    align-items: center;
  }
  max-height: 0;
  overflow: visible;
`

const Info = styled.div`
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  color: #425466;
  max-height: 0;
  overflow: visible;
  margin-top: 75px;

  @media (max-width: 440px) {
    display: none;
  }
`
const Message = styled.div`
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  color: #425466;
  margin-bottom: 32px;
  @media (max-width: 744px) {
    display: none;
  }
`
const Loading = styled.div`
  font-size: 21px;
  line-height: 25px;
  text-align: center;
  color: #333333;
  margin-bottom: 24px;
`

const BarBack = styled.div`
  width: 320px;
  height: 8px;
  background: #d9d9d9;
  border-radius: 4px;
  display: flex;
  justify-content: flex-start;
`

const BarButton = styled.div`
  ${props => `width: ${320 * props.percent}px;`}
  background: #6361FF;
  height: 8px;

  border-radius: 4px;
`
const Percent = styled.div`
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  text-align: center;
  color: #8d9092;
  margin-top: 8px;
`
const DownloadTitle = styled.div`
  font-size: 38px;
  line-height: 48px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #333333;
  margin: 24px 0;
  @media (max-width: 744px) {
    font-size: 32px;
    line-height: 32px;
  }
`

const DownloadMessage = styled.div`
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: #425466;
  @media (max-width: 744px) {
    font-size: 16px;
    line-height: 26px;
  }
`
const DownloadButton = styled.div`
  min-width: 156px;
  height: 48px;
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  background: #6361ff;
  border-radius: 8px;
  margin-top: 40px;
  font-size: 17px;
  line-height: 20px;
  color: #ffffff;
  cursor: pointer;
  @media (max-width: 440px) {
    width: 100%;
  }
`

const ErrorMessageHolder = styled.div`
  background: red;
  height: 100%;
  width: 0px;
  position: absolute;
  left: 50%;
  bottom: 0;
  padding-bottom: 258px;
  padding-top: 258px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  @media (max-width: 744px) {
    justify-content: flex-start;
  }
`

const Restart = styled(ToPdf)`
  margin-top: auto;
  display: flex;
  margin-bottom: 32px;
  margin-top: 32px;
`

const RestartSecond = styled(Restart)`
  justify-content: center;
  margin-top: 32px;
  position: absolute;
  bottom: 0;
  width: 90vw;
  max-width: 1080px;
`
const CtaRestat = styled(CtaPopup)`
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    margin: 0 8px;
  }
`

const ScrollTrigger = styled.div`
  min-width: 96px;
  min-height: 650px;
  /* border: 1px dashed red; */

  z-index: 9999;
`

const STPositioner = styled.div`
  max-width: 0;
  max-height: 0;
  overflow: visible;
  position: relative;
  z-index: 9999;

  left: 50%;

  ${props => props.left && `left: ${props.left};`}
  ${props => props.top && `top: ${props.top};`}

  @media (max-width: 1100px) {
    position: absolute;
    top: 100px;
    ${props => props.resLeft && `left: ${props.resLeft};`}
    ${props => props.resLeft === "special" && `left: calc(95vw - 95px);`}
  }

  @media (max-width: 475px) {
    ${props => props.resLeft && `left: -11vw;`}
    ${props => props.resLeft === "special" && `left: calc(95vw - 40px);`}
  }
`
function RearrangeTool() {
  const [file, setFile] = useState(null)
  const [errorFiles, setErrorFiles] = useState([])
  const [repository, setRepository] = useState([])
  const [fileRendered, setFileRendered] = useState(0)
  const [dragElement, setDragElement] = useState()
  const [filesSize, setFilesSize] = useState(0)
  const [filesSizeNum, setFilesSizeNum] = useState(0)
  const [download, setDownload] = useState(null)
  const [errors, setErrors] = useState([])
  const [showAdd, setShowAdd] = useState(false)

  const [deleteFile, setDeleteFile] = useState(null)
  const [loading, setLoading] = useState(false)

  const pdfHolder = useRef(null)
  const pdfPreview = useRef(null)

  function formatBytes(bytes, decimals = 2) {
    if (!+bytes) return "0 Bytes"

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
  }

  useEffect(() => {
    if (deleteFile) {
      setRepository(rep => {
        let rawRep = []
        rep.filter(r => {
          if (r.name != deleteFile.name) {
            rawRep.push(r)
          }
        })
        return [...rawRep]
      })

      setDeleteFile(null)
    }
  }, [deleteFile])

  useEffect(() => {
    let size = 0
    repository.forEach(f => {
      size += f.size
    })

    setFilesSize(formatBytes(size, 2))
  }, [repository])

  useEffect(() => {
    if (pdfHolder?.current) {
      function detectScrollability() {
        if (pdfPreview.current.scrollWidth > pdfPreview.current.clientWidth) {
          pdfPreview.current.style.justifyContent = "normal"
          setShowAdd(true)
        } else {
          pdfPreview.current.style.justifyContent = "center"
          setShowAdd(false)
        }
      }
      window.addEventListener("resize", () => {
        detectScrollability()
      })
      const observer = new MutationObserver(detectScrollability)

      detectScrollability()
      observer.observe(pdfPreview.current, {
        attributes: true,
        childList: true,
        subtree: true,
      })
    }
  }, [pdfHolder.current])

  const { setScroll } = useContext(GlobalAuthContext)

  const [myPopUp, setMyPopUp] = useState("hide")
  function closePopup() {
    setMyPopUp("hide")
    setScroll("hide")
  }
  //open popup function
  function openPopup(e) {
    setMyPopUp("show")
    setScroll("show")
  }

  function isMobile() {
    const userAgent = navigator.userAgent
    return (
      /Android/i.test(userAgent) ||
      /iPhone|iPad|iPod/i.test(userAgent) ||
      /Windows Phone|IEMobile|Mobile/i.test(userAgent)
    )
  }

  useEffect(() => {
    if (file) {
      const parent = document.getElementById("canvas-storage")

      const observer = new MutationObserver((mutationsList, observer) => {
        for (let mutation of mutationsList) {
          if (mutation.type === "childList") {
            let leftTrigger = document.getElementById("leftTrigger")
            let rightTrigger = document.getElementById("rightTrigger")

            // define the scroll speed
            let scrollSpeed = 1

            // define the scroll interval id
            let scrollIntervalId
            window.speedUp = 0
            // define the scroll functions
            function scrollLeft() {
              let canvasStorage = document.getElementById("preview")
              if (window.speedUp < 3) {
                window.speedUp += 0.002
              }

              canvasStorage.scrollLeft -=
                scrollSpeed + (window.speedUp ? window.speedUp : 0)
            }

            function scrollRight() {
              let canvasStorage = document.getElementById("preview")
              if (window.speedUp < 3) {
                window.speedUp += 0.002
              }

              canvasStorage.scrollLeft +=
                scrollSpeed + (window.speedUp ? window.speedUp : 0)
            }

            // drag events
            ;["dragenter", "dragover"].forEach(eventName => {
              leftTrigger.addEventListener(eventName, function (event) {
                event.preventDefault()
                event.stopPropagation()
                if (!scrollIntervalId) {
                  scrollIntervalId = setInterval(scrollLeft, 50) // Adjust the interval as needed
                }
              })

              rightTrigger.addEventListener(eventName, function (event) {
                event.preventDefault()
                event.stopPropagation()
                if (!scrollIntervalId) {
                  scrollIntervalId = setInterval(scrollRight, 50) // Adjust the interval as needed
                }
              })
            })
            ;["dragleave", "drop"].forEach(eventName => {
              leftTrigger.addEventListener(eventName, function (event) {
                event.preventDefault()
                event.stopPropagation()
                if (scrollIntervalId) {
                  window.speedUp = 0
                  clearInterval(scrollIntervalId)
                  scrollIntervalId = null
                }
              })

              rightTrigger.addEventListener(eventName, function (event) {
                event.preventDefault()
                event.stopPropagation()
                if (scrollIntervalId) {
                  window.speedUp = 0

                  clearInterval(scrollIntervalId)
                  scrollIntervalId = null
                }
              })
            })
            ;["touchstart", "contextmenu"].forEach(eventName => {
              leftTrigger?.addEventListener(eventName, function (event) {
                event.preventDefault()
                event.stopPropagation()
              })

              rightTrigger?.addEventListener(eventName, function (event) {
                event.preventDefault()
                event.stopPropagation()
              })
            })

            ////
            const addedNodes = mutation.addedNodes
            let i = 1
            for (let node of addedNodes) {
              if (node.nodeType === Node.ELEMENT_NODE) {
                let pdfdisplay = node

                function handleDragStart(e) {
                  this.style.opacity = "0.4"

                  setDragElement(this)
                  window.dragElement = this
                  window.firstPick = this.style.order

                  e.dataTransfer.effectAllowed = "move"
                  e.dataTransfer.setData("text/html", this.innerHTML)
                }

                function handleDragStartTouch(e) {
                  e.preventDefault()

                  this.style.opacity = "0.4"

                  this.touchStartX = e.touches[0].clientX
                  this.touchStartY = e.touches[0].clientY
                  this.startX = parseFloat(this.style.left) || 0
                  this.startY = parseFloat(this.style.top) || 0

                  this.clone = this.cloneNode(true)
                  this.clone.style.position = "absolute"
                  this.clone.style.pointerEvents = "none" // Ensure that the clone doesn't interfere with events
                  this.clone.style.touchAction = "none" // Ensure that the clone doesn't interfere with events
                  this.clone.style.zIndex = 2

                  this.clone.style.opacity = "0.7" // Make the clone semi-transparent
                  document.body.appendChild(this.clone)
                }

                function handleDragMoveTouch(e) {
                  // calculate the new position
                  var x = e.touches[0].clientX - this.offsetWidth / 2
                  var y = e.touches[0].clientY - this.offsetHeight / 2

                  // move the element to the new position
                  this.clone.style.left = `-1000px`
                  this.clone.style.top = `-1000px`
                  let target = document.elementFromPoint(
                    e.changedTouches[0].clientX,
                    e.changedTouches[0].clientY
                  )
                  let trigger = target

                  while (
                    (target && !target.classList.contains("pdfdisplay")) ||
                    target === this
                  ) {
                    target = target.parentElement
                  }

                  while (
                    trigger &&
                    !trigger.classList.contains("rightTrigger") &&
                    !trigger.classList.contains("leftTrigger") &&
                    trigger !== this
                  ) {
                    trigger = trigger.parentElement
                  }
                  if (trigger) {
                    if (
                      !scrollIntervalId &&
                      trigger.classList.contains("rightTrigger")
                    ) {
                      scrollIntervalId = setInterval(scrollRight, 1)
                    }
                    if (
                      !scrollIntervalId &&
                      trigger.classList.contains("leftTrigger")
                    ) {
                      scrollIntervalId = setInterval(scrollLeft, 1)
                    }
                  } else {
                    if (scrollIntervalId) {
                      window.speedUp = 0
                      clearInterval(scrollIntervalId)
                      scrollIntervalId = null
                    }
                  }

                  if (target && target !== this) {
                    const canvas = target.getElementsByTagName("img")[0]
                    // target.style.border = "1px solid red"
                    canvas.style.outline = "rgba(216, 219, 253, 0.5) solid 10px"
                    canvas.style.border = "1px solid #425466"

                    if (this.targetBefore !== target) {
                      const canvasBefore = this.targetBefore?.getElementsByTagName(
                        "img"
                      )[0]
                      canvasBefore?.style?.removeProperty("outline")
                      canvasBefore?.style?.removeProperty("border")
                      this.targetBefore = target
                      // Only proceed if the target is a draggable element and not the element that was dragged
                      if (target && target !== this) {
                        // Swap the order of the dragged element and the target element
                        let destination = target.style.order
                        let picked = this.style.order

                        this.style.order = destination
                        target.style.order = picked
                      }
                    }
                  } else if (this.targetBefore) {
                    const canvasBefore = this.targetBefore?.getElementsByTagName(
                      "img"
                    )[0]
                    canvasBefore?.style?.removeProperty("outline")
                    canvasBefore?.style?.removeProperty("border")
                    this.targetBefore = null
                  }

                  this.clone.style.left = `${x}px`
                  this.clone.style.top = `${y}px`
                }

                function handleDragEnd(e) {
                  this.style.opacity = "1"
                }

                function handleDragEndTouch(e) {
                  this.style.opacity = "1"
                  this.style.position = "relative"
                  // Get the topmost element at the touchend position
                  let target = document.elementFromPoint(
                    e.changedTouches[0].clientX,
                    e.changedTouches[0].clientY
                  )

                  clearInterval(scrollIntervalId)

                  while (target && !target.classList.contains("pdfdisplay")) {
                    target = target.parentElement
                  }

                  // Only proceed if the target is a draggable element and not the element that was dragged
                  if (target && target !== this) {
                    // Swap the order of the dragged element and the target element
                    let destination = target.style.order
                    let picked = this.style.order

                    this.style.order = destination
                    target.style.order = picked
                  }

                  // Reset styles
                  this.style.zIndex = 1
                  this.style.left = null
                  this.style.top = null

                  this.clone.remove()
                  if (target) {
                    const canvas = target.getElementsByTagName("img")[0]
                    canvas.style.removeProperty("outline")
                    canvas.style.removeProperty("border")
                  }
                  if (this.targetBefore !== target && this.targetBefore) {
                    const canvasBefore = this.targetBefore?.getElementsByTagName(
                      "img"
                    )[0]
                    canvasBefore?.style?.removeProperty("outline")
                    canvasBefore?.style?.removeProperty("border")
                    this.targetBefore = target
                  }
                }
                function handleDragOver(e) {
                  e.preventDefault()
                  return false
                }

                function handleDragEnter(e) {
                  if (window.dragElement !== this) {
                    let destination = this.style.order
                    let picked = window.dragElement.style.order

                    this.style.order = picked
                    window.dragElement.style.order = destination
                  }
                }

                function handleDragLeave(e) {}

                function handleDrop(e) {
                  e.stopPropagation() // stops the browser from redirecting.

                  return false
                }

                if (!isMobile()) {
                  //Desktop and Iphone
                  pdfdisplay.addEventListener("dragstart", handleDragStart)
                  pdfdisplay.addEventListener("dragover", handleDragOver)
                  pdfdisplay.addEventListener("dragenter", handleDragEnter)
                  pdfdisplay.addEventListener("dragleave", handleDragLeave)
                  pdfdisplay.addEventListener("dragend", handleDragEnd)
                  pdfdisplay.addEventListener("drop", handleDrop)
                } else {
                  /// Mobile
                  pdfdisplay.addEventListener(
                    "touchstart",
                    handleDragStartTouch,
                    { passive: false }
                  )
                  pdfdisplay.addEventListener("touchmove", handleDragMoveTouch)

                  pdfdisplay.addEventListener("touchend", handleDragEndTouch)

                  pdfdisplay.addEventListener("contextmenu", function (event) {
                    event.preventDefault()
                  })
                }

                // const canvas = pdfdisplay.getElementsByTagName("img")[0]
                // let ctx = canvas.getContext("2d")

                // // Create new img element
                // let img = new Image()

                // // Once image loads, draw it on the canvas
                // img.onload = function () {
                //   canvas.width = img.width
                //   canvas.height = img.height
                //   ctx.drawImage(img, 0, 0, img.width, img.height)
                // }

                // let source = pdfdisplay.getAttribute("data-img")

                // img.src = source
                // pdfdisplay.firstChild?.appendChild(canvas)
              }
              i++
            }
          }
        }
      })

      observer.observe(parent, { childList: true })
    }
  }, [file])

  useEffect(() => {
    if (fileRendered == 1) {
      setFileRendered(0)
      setLoading(false)
      // console.log(errorFiles)
      if (errorFiles.length == 0) {
        setRepository([file])
        setErrorFiles([])
      } else {
        setFile(null)
      }
    } else {
      // console.log("File rednered: ", fileRendered)
    }
  }, [fileRendered])

  function removePassProtected(fileName) {
    let str = fileName + " is password protected"
    ErrorPopup(str)
  }

  useEffect(() => {
    // console.log("File: ", file)
  }, [file])

  async function pdfInput(file) {
    const fileReader = new FileReader()
    fileReader.onload = async function () {
      const pdfData = new Uint8Array(this.result)
      await displayPdfSyncRearrange(
        pdfData,
        file,
        "canvas-storage",
        1,
        1,
        setFileRendered,
        removePassProtected,
        setErrorFiles,
        repository.length
      )
    }
    fileReader.readAsArrayBuffer(file)
  }

  const fileTypes = ["pdf"]

  const handleChange = file => {
    const formData = new FormData()
    formData.append("excel", file)
    setLoading(true)

    setFile(file)
    pdfInput(file)
  }

  const filesSubmit = () => {
    let pdfdisplays = document.querySelectorAll(".pdfdisplay")
    let order = []
    pdfdisplays.forEach((node, i) => {
      order[node.style.order - 1] = node.getAttribute("order")
    })

    const formData = new FormData()

    let fileName = repository[0].name

    formData.append(repository[0].name, repository[0])

    let data = {
      formData: formData,
      fileName: fileName,
      order: encodeURIComponent(JSON.stringify(order)),
    }
    setDownload(true)
    RearrangeFiles(data, setDownload)
  }
  function restartTool(e) {
    window.location.reload()
  }
  function ErrorPopup(message) {
    let oldEr = [...errors, message]
    setErrors(er => [...er, message])
    setTimeout(() => {
      if (
        oldEr.length == errors.length &&
        JSON.stringify(oldEr) == JSON.stringify(errors)
      ) {
        setErrors([])
      }
    }, 5000)
  }
  if (download != null) {
    return (
      <DownloadScreen>
        <MergedIcon />
        {download === true && (
          <DownloadTitle>Your PDF is now being rearranged</DownloadTitle>
        )}

        {download !== true && (
          <DownloadTitle>Your PDF pages have been rearranged</DownloadTitle>
        )}
        <DownloadMessage>
          Your file will automatically download once it's ready. If it doesn't,
          use the button below.{" "}
        </DownloadMessage>
        {download === true && (
          <DownloadButton>
            <LoadingCircle />
            Processing...
          </DownloadButton>
        )}
        {download !== true && (
          <DownloadButton onClick={() => download.click()}>
            Download File
          </DownloadButton>
        )}
        <Restart>
          <div className="desktop">Need to rearrange another file?</div>
          <CtaRestat onClick={restartTool}>
            <RestartIcon /> Start Over{" "}
          </CtaRestat>
        </Restart>
        <UsefulTools />
      </DownloadScreen>
    )
  }

  return (
    <CanvasStyle>
      <ErrorMessageHolder>
        {errors.map(m => {
          return <ErrorMessage message={m}></ErrorMessage>
        })}
      </ErrorMessageHolder>
      {!file && (
        <div className="firstScreen">
          <RearrangeIcon />
          <h1 id="MergePDF">Rearrange PDF Pages Online</h1>
          <Title>
            Easily rearrange PDF pages for free, directly on your browser. It's
            100% free, instant, and works on any device.
          </Title>
          <FileUploaderStyle>
            <FileUploader
              handleChange={handleChange}
              multiple={false}
              types={fileTypes}
              maxSize={20}
              onSizeError={() => {
                ErrorPopup("Your file needs to be under 20MB size")
              }}
              classes="drop_area drop_zone "
              children={
                <div className="column">
                  <UploadIcon />
                  <div className="title"></div>
                  <div className="info"></div>
                  <button>Upload file</button>
                </div>
              }
            />
          </FileUploaderStyle>
          <Row>
            <Offline>Offline</Offline>
            <ToPdf>
              <div className="desktop">Rather work offline?&nbsp;</div>
              <CtaPopup onClick={openPopup}>
                Try PDF Pro for free today! <ArrowTry />{" "}
              </CtaPopup>
            </ToPdf>
          </Row>
          <Row>
            <Warning />
            <GDPRwarning>
              <Disclaimer />
              <PrivacyQuestion />
            </GDPRwarning>
          </Row>
          {myPopUp === "show" && <TryPdf close={closePopup} />}
        </div>
      )}
      <LoadingScreen hide={loading}>
        <Loading>Loading... </Loading>
        <BarBack>
          <BarButton percent={fileRendered} />{" "}
        </BarBack>
        <Percent>{(fileRendered * 100).toFixed(0)}%</Percent>
      </LoadingScreen>
      <STPositioner
        id="leftTrigger"
        left={"-57%"}
        resLeft={"5vw"}
        top={"-120px"}
      >
        <ScrollTrigger className="leftTrigger" />
      </STPositioner>
      {file && (
        <SecondScreen>
          <Message>Drag and drop pages to rearrange them</Message>

          <Pdfpreview id="preview" ref={pdfPreview}>
            <Pdfholder id="canvas-storage" ref={pdfHolder}>
              {" "}
            </Pdfholder>
          </Pdfpreview>

          <ButtonHolder>
            <MergeButton onClick={filesSubmit}>
              Export PDF <ArrowRight />
            </MergeButton>
          </ButtonHolder>
          <RestartSecond>
            <div className="desktop">Need to rearrange another file?</div>
            <CtaRestat onClick={restartTool}>
              <RestartIcon /> Start Over{" "}
            </CtaRestat>
          </RestartSecond>
        </SecondScreen>
      )}
      <STPositioner
        id="rightTrigger"
        left={"47%"}
        resLeft={"special"}
        top={"-620px"}
      >
        <ScrollTrigger className="rightTrigger" />
      </STPositioner>
    </CanvasStyle>
  )
}

export default RearrangeTool
