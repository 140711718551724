import client from "../toolApi"
import { DataLayer } from "../../helpers/GA"
export const RearrangeFiles = (data, setDownload) =>
  client
    .post("/uploadNew", data.formData)
    .then(res => {
      DataLayer("Online tool", "Upload", "Rearrange")
      window.fileName = res.data.message
      client
        .get(`/sortTool?pages=${data.order}&fileName=${res.data.message}`)
        .then(res => {
          DataLayer("Online tool", "Rearrange", "Rearrange")
          client
            .get(`/downloadSort?fileName=${window.fileName}`, {
              responseType: "blob",
            })
            .then(res => {
              console.log("Downloaded: ", res)
              const link = document.createElement("a")
              setDownload(link)
              link.href = URL.createObjectURL(new Blob([res.data]))
              link.download = data.fileName
              document.body.appendChild(link)
              link.click()
              DataLayer("Online tool", "Download", "Rearrange")
            })
            .catch(() => {
              DataLayer("Online tool", "Error download", "Rearrange")
            })
        })
        .catch(() => {
          DataLayer("Online tool", "Error Rearrange", "Rearrange")
        })
    })
    .catch(() => {
      DataLayer("Online tool", "Error upload", "Rearrange")
    })
